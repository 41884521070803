@font-face {
  font-family: 'poppins-regular';
  src: url(./../../../public/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: 'poppins-medium';
  src: url(./../../../public/fonts/Poppins-Medium.ttf);
}

@font-face {
  font-family: 'poppins-bold';
  src: url(./../../../public/fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: 'poppins-semibold';
  src: url(./../../../public/fonts/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: 'Hahmlet-Medium';
  src: url(./../../../public/fonts/Hahmlet-Medium.ttf);
}

@font-face {
  font-family: 'Hahmlet-Semibold';
  src: url(./../../../public/fonts/Hahmlet-SemiBold.ttf);
}

@font-face {
  font-family: 'Hahmlet-Bold';
  src: url(./../../../public/fonts/Hahmlet-Bold.ttf);
}

@font-face {
  font-family: 'Manrope-Medium';
  src: url(./../../../public/fonts/Manrope-Medium.ttf);
}

@font-face {
  font-family: 'Manrope-Bold';
  src: url(./../../../public/fonts/Manrope-Bold.ttf);
}

.font-hahmlet-medium {
  font-family: 'Hahmlet-Medium';
}

.font-hahmlet-bold {
  font-family: 'Hahmlet-Bold';
}

.font-hahmlet-semibold {
  font-family: 'Hahmlet-Semibold';
}

.font-manrope-medium {
  font-family: 'Manrope-Medium';
}

.font-manrope-bold {
  font-family: 'Manrope-Bold';
}

.font-pop-bold {
  font-family: 'poppins-bold';
}

.font-pop-medium {
  font-family: 'poppins-medium';
}

.font-pop-semibold {
  font-family: 'poppins-semibold';
}

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semi-bold: 500;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$font-weight-base: $font-weight-normal;
