@import '../scss/variables/color';

.user-edit-form {
  .upload-img {
    position: relative;
    height: 150px;
    input {
      width: 100%;
      z-index: 999;
      height: 100%;
      position: absolute;
      opacity: 0;
    }
    .uploader-backdrop {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 25px;
      border-radius: 0.5rem;
      background: $secondary-tint-2;
      border: 1px solid $secondary-tint-2;
      transition: box-shadow 0.3s, border-color 0.3s;
      display: flex;
      justify-content: center;
      align-items: center;
      .user-img {
        position: relative;
        height: 80px;
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px dashed $primary;
        border-radius: 50%;
        background-color: $light-200;
        cursor: pointer;
        .fa-plus {
          position: absolute;
          bottom: 2px;
          right: 2px;
          width: 20px;
          height: 20px;
          font-size: 14px;
          background-color: $primary;
          color: $white;
          border-radius: 50%;
          text-align: center;
          line-height: 20px;
        }
      }
    }
  }
  div {
    position: relative;
    > label {
      margin-top: 1rem;
      font-weight: 700;
      color: $secondary-tint-1;
      font-size: 0.815rem;
    }
    > input {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-radius: 0;
      padding-left: 0;
      color: $black;
      &:focus {
        box-shadow: none;
        border-bottom-color: $primary;
      }
    }
    > textarea {
      &:focus {
        box-shadow: none;
        border-color: $primary;
      }
    }
    > .validation-sign {
      z-index: 999;
      position: absolute;
      bottom: 24px;
      right: 4px;
    }
    .input-group {
      .input-group-text {
        border-top: 0;
        padding-right: 0.5rem;
        border-left: 0;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          height: 20px;
          width: 1px;
          background-color: $secondary-tint-2;
        }
      }
    }
  }
  .gender-button {
    button {
      width: 50%;
      background-color: $white;
      border: 1px solid $secondary-tint-2;
      transition: all linear 0.15s;
      i {
        color: $primary;
        transition: all linear 0.15s;
      }
    }
  }
}
