@import './scss/theme/custom-bootstrap';
@import './scss/scroller';

#root {
  > div:not(.Toastify) {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    > * {
      &:not(nav, footer) {
        flex: 1;
      }
    }
    nav {
      height: 100%;
    }
    footer {
      height: 100%;
    }
  }
}

.nexo-logo {
  width: 216px;
}

ul,
ol,
dl {
  list-style: none;
  margin: 0 !important;
  padding: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'poppins-bold';
}

a {
  text-decoration: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.object-shadow {
  filter: drop-shadow(1px 1px 2px #0003);
}

// bootstrap global class override
.btn-primary {
  color: $white !important;
}

.bg-primary-shade {
  background-color: rgba($primary, 0.1) !important;
}

.bg-secondary-shade {
  background-color: rgba($secondary, 0.35) !important;
}

.btn-rounded {
  height: 34px;
  padding: 0 !important;
  width: 34px;
  border-radius: 50% !important;
  text-align: center;
  line-height: 34px !important;
  &.btn-sm {
    width: 20px !important;
    height: 20px !important;
    font-size: 12px !important;
    line-height: 20px !important;
  }
  &.btn-md {
    width: 24px !important;
    height: 24px !important;
    line-height: 24px !important;
    font-size: 14px !important;
  }
  &.btn-lg {
    width: 42px !important;
    height: 42px !important;
    font-size: 28px !important;
    line-height: 42px;
  }
}

.btn {
  &.btn-primary-white {
    background-color: $white;
    color: $primary;
    &:hover,
    &:active,
    &:focus {
      background-color: $primary !important;
      color: $white !important;
      border-color: $primary !important;
    }
  }
  &.btn-outline-primary {
    &:focus,
    &:active {
      color: $white !important;
    }
  }
}

.overlay {
  background-color: rgba($gray, 0.5);
}

.checkout-price-border-bottom {
  border-bottom: 1px dashed #638876;
}

.fs-xs {
  font-size: 0.65rem;
}

.fs-md {
  font-size: 0.875rem;
}

input.placeholder-gray {
  &::placeholder {
    color: $gray-100 !important;
  }
}

.border-bottom {
  &.border-bottom-dashed {
    border-bottom-style: dashed !important;
  }
}

.confirmation-box-height {
  min-height: calc(100vh - 201px) !important;
}

.carousel-custom {
  position: relative !important;
  .carousel-control-prev {
    color: rgba($primary, 0.8);
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: -8px;
    transform: translateY(-50%);
    &:hover,
    &:focus,
    &:active {
      color: $primary;
    }
  }
  .carousel-control-next {
    color: rgba($primary, 0.8);
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    right: -8px;
    transform: translateY(-50%);
    &:hover,
    &:focus,
    &:active {
      color: $primary;
    }
  }
}

button.accordion-toggle {
  border-radius: 8px;
  padding: 0.375rem 0.5rem;
  box-shadow: 0 3px 5px rgba($gray, 0.2);
  transition: all linear 0.2s;
  &.collapsed {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0;
    box-shadow: none;
  }
}

.dropdown-item {
  &:active,
  &:focus,
  &:hover {
    color: $white !important;
    background: $primary !important;
  }
}

.shadow-md {
  box-shadow: 0 5px 10px #0003;
}

.Toastify__toast-container {
  text-align: center !important;
  top: auto !important;
  bottom: 0 !important;
}

.Toastify__toast {
  margin: auto !important;
}

.full-screen-spinner-container,
.transparent-spinner-container {
  background-color: $primary;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.full-screen-spinner-container {
  background-color: $primary;
}

.transparent-spinner-container {
  background-color: rgba(0, 0, 0, 0.5);
}

.spinner-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.line-fix-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-fix-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-fix-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.carousel-control-next {
  right: -5px !important;
}

.carousel-control-prev {
  left: -5px !important;
}

.message-pop {
  z-index: 200;
  position: fixed;
  width: 50px;
  height: 50px;
  font-size: 26px;
  border-radius: 50%;
  bottom: 50px;
  right: 20px;
  text-align: center;
  line-height: 52px;
  background-color: $primary;
  color: $white;
}

.Toastify {
  position: absolute !important;
}

.user-dropdown {
  width: 18rem;
  button {
    padding: 0;
    border: 0;
    background-color: transparent;
  }
  .user-img {
    position: absolute;
    bottom: -32px;
    left: 50%;
    transform: translateX(-50%);
    width: 65px;
    aspect-ratio: 1 / 1;
    border: 3px solid $white;
    border-radius: 50%;
    box-shadow: 0 4px 4px 0 rgba($black, 10%);
    .fa-plus {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 18px;
      height: 18px;
      font-size: 10px;
      line-height: 18px;
      text-align: center;
      color: $white;
      background-color: $primary;
      border-radius: 50%;
    }
    img {
      width: 100%;
      border-radius: 50%;
      aspect-ratio: 1 / 1;
      object-fit: cover;
    }
  }
  .user-edit-tool {
    position: absolute;
    right: 16px;
    top: 12px;
  }
}

.btn-outline-primary {
  &:hover,
  &:focus,
  &:active,
  &.show {
    color: $white !important;
    background-color: $primary;
  }
}

.user-accordion {
  .accordion-button {
    &:not(.collapsed) {
      background-color: transparent;
      box-shadow: none;
      &::after {
        transform: rotate(0deg);
      }
    }
    &:focus {
      box-shadow: none;
    }
    &:after {
      font-family: FontAwesome;
      color: rgba($secondary, 0.25);
      content: '\f107';
      background: none !important;
      transform: rotate(-90deg);
    }
  }
}

.border-bottom {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.bg-primary-gradient {
  background-image: $primary-gradient;
}

.otpContainer {
  margin: 5% auto;
  .otpInput {
    width: 3rem !important;
    height: 3rem;
    margin: 0 0.5rem;
    font-size: 1rem;
    text-align: center;
    border: 1px solid #e2e8f0;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
  }
}

.otpInput {
  -moz-appearance: textfield;
}

.otpInput::-webkit-inner-spin-button,
.otpInput::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cart-item-count-container {
  background-color: $warning;
  color: white;
  padding: 0.5rem;
  font-size: 0.5rem;
  position: absolute;
  top: -7px;
  right: -7px;
  height: 7px;
  width: 7px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;

  .cart-item-count {
    top: -16px;
    left: -1px;
  }
}

.dashboard-image {
  height: 60px;
  width: 60px;
  border: 1px solid $warning-2;
}

/*  Add breakpoint for iPhone */
@media only screen and (max-width: 375px) {
  .otpInput {
    width: 1.5rem !important;
    height: 1.5rem;
    font-size: 1rem;
    padding: 8px;
  }
}
.border-dashed {
  border-style: dashed !important;
}

.upload-supplier-product-image-container {
  position: relative;
  height: 200px;
  width: 200px;
  border: 1px dashed $primary;
  cursor: pointer;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;

  i {
    height: 50px;
  }
}

.supplier-product-image-container {
  height: 50px;
  width: 50px;
  border: 1px solid $warning-2;
  position: relative;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  .fa-window-close {
    top: 0;
    right: 0;
    background-color: white;
    height: fit-content;
  }
}

.privacyPolicyContainer {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  padding: 1em;
}

.vector_image_auth {
  position: relative;
  &::after {
    content: '';
    background-image: url('./../public/images/vector-square.png');
    background-size: auto;
    width: 56px;
    height: 56px;
    position: absolute;
    left: 170px;
    top: -35px;
  }
  &::before {
    content: '';
    background-image: url('./../public/images/vector-round.png');
    background-size: auto;
    background-repeat: no-repeat;
    width: 84px;
    height: 84px;
    position: absolute;
    right: 100px;
    bottom: 34px;
  }
}

.text-ellipse {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mobile-app-modal {
  .modal-content,
  .modal-body {
    border-radius: 0.75rem;
  }
  .modal-lg {
    --bs-modal-width: 540px;
  }
  .modal-body {
    position: relative;
    background-color: $primary;
    padding: 1rem 3rem;
    button {
      position: absolute;
      top: -12px;
      right: -12px;
    }
  }
}
