@import '../variables/mixins';

$primary: #ff7c76;
$primary-2: #feb4b1;
$primary-dark: #dc4758;
$primary-light: #ffe7e9;
$danger: #eb5757;
$success: #01b41f;
$black: #151515;

$secondary: #1f1a50;
$warning: #ffab03;
$warning-light: #fedea0;

$gray: #636472;
$gray-100: #808b9a;

$light-2: #f9fafb;
$light-100: #f5f5f5;
$light-200: #f6f6f6;

$dark: #9098b1;
$white: #fff;
$snow-white: #f8f9fa;

$warning-2: #ffbb47;
$info-2: #85d2de;
$purple-2: #b1a5e1;
$success-2: #a8dc9b;
$red-2: #fbe4e6;

$blue: #0095ff;
$mint-green: #00e096;
$violet: #884dff;
$orange: #ff8f0d;

$primary-shade-1: shade($primary, 15%);

$secondary-tint-1: tint($secondary, 65%);
$primary-tint-1: tint($primary, 85%);
$secondary-tint-2: tint($secondary, 85%);

$primary-gradient: linear-gradient(180deg, $primary-2 0%, $primary 100%);
