@import '../scss/theme/custom-bootstrap';

footer {
  font-family: 'poppins-medium';
  margin-top: 0.875rem;
  padding: 0.75rem;
  background-color: $primary-light;
  border-top: 1px solid $primary-light;
}

.footer-nav {
  display: flex !important;
  .footer-navitem {
    padding: 0.25rem 1rem 0;
    .footer-navlink {
      font-size: 0.875rem;
      font-family: 'poppins-medium';
      text-decoration: none !important;
      color: $secondary !important;
    }
  }
}
