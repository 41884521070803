@import '../scss/variables/color';

.appointment-nav {
  border: 1px solid $secondary-tint-2 !important;
  position: relative;
  button {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    background-color: transparent;
    width: 180px;
    border: none !important;
    transition: all linear 0.2s;
    color: $gray;
    &.active {
      background: transparent;
      color: $white;
    }
  }
}

.doc-card-container {
  height: 64vh;
  overflow-y: auto;
  .vet-doc-card {
    margin-bottom: 0.65rem;
    &:last-child {
      margin-bottom: 0;
    }
    .card-body {
      .vet-doc-img {
        width: 100px;
        img {
          width: 100%;
        }
      }
    }
  }
}

.active-area {
  display: inline-block;
  position: absolute;
  width: 50% !important;
  height: 100% !important;
  top: 0;
  left: 0;
  z-index: -1;
  background: $primary;
  border-radius: 50px;
  box-shadow: 0 0 8px rgba($black, 0.25);
}
