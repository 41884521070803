@import '../../scss/variables/sidebar-vars';

.sidebar-container {
    position: fixed;
    z-index: 9;
    left: 0;
    top: 0;
    width: $sidebar-width;
    padding: $sidebar-padding-y $sidebar-padding-x;
    background-color: $sidebar-bg;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    transition: all linear 0.15s;
    .sidebar-brand {
        display: flex;
        align-items: center;
        img {
            margin-right: 1rem;
        }
    }
    .sidebar-nav {
        padding-top: 2.75rem;
        .nav-menu {
            width: 100%;
            .nav-item {
                width: 100%;
                .sidebar-navlink {
                    display: inline-block !important;
                    padding: $sidebar-navlink-padding-y $sidebar-navlink-padding-x;
                    width: 100%;
                    color: $sidebar-navlink-color;
                    border-radius: $sidebar-navlink-radius;
                    display: flex;
                    align-items: center;
                    transition: all ease-in 0.15s;
                    > span {
                        line-height: $sidebar-navlink-text-size;
                        font-size: $sidebar-navlink-text-size;
                        padding-left: 1.25rem;
                    }
                    > i {
                        text-align: center;
                        width: 30px;
                        font-size: $sidebar-navlink-icon-size;
                    }
                    &:hover{
                        color: $sidebar-navlink-hover-color;
                    }
                }
                &.active {
                    .sidebar-navlink {
                        background-color: $sidebar-navlink-hover-bg;
                        color: $sidebar-navlink-active-color;
                    }
                }
            }
        }
    }
    @media (max-width: 991px) {
        left: calc(-#{$sidebar-width} - 15px);
        box-shadow: 8px 0 15px 0 #0003;
    }
    &.toggled {
        left: 0;
    }
}

.sidebar-toggler {
    transition: all linear 0.15s;
    position: absolute;
    left: -48px;
    top: 16px;
    z-index: 11;
    button {
        &:active,&:focus {
            border: none;
        }
    }
    &.toggled {
        position: fixed;
        left: 275px;
        top: 24px;
    }
    @media (max-width: 991px) {
        left: 16px;
    }
}