@import '/src/scss/variables/color';

.vet-doc-img {
  width: 100%;
  aspect-ratio: 1 / 1;
  position: relative;
  img {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 0.5rem;
  }
  .doc-info {
    position: absolute;
    bottom: -2rem;
    left: 0;
    width: 100%;
    padding: 0.65rem 1rem;
    height: 150px;
    overflow-y: auto;
    .doc-rating {
      padding: 0.5rem 0.4rem 0.75rem;
      border-radius: 1.25rem;
    }
  }
}

.review-card {
  .reviewer-info {
    position: relative;
    padding-left: 6.25rem;
    padding-top: 0.75rem;
    p {
      line-height: 1.2;
    }
    .reviewer-img {
      position: absolute;
      top: -12px;
      left: 16px;
      img {
        height: 72px;
        width: 72px;
        border-radius: 15px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
      }
    }
  }
}

.vet-card {
  border-radius: 1rem;
  background-color: $white;
  padding: 1rem;
  margin-right: 0.5rem;
  p {
    line-height: 1.2;
    text-wrap: nowrap;
  }
  img {
    height: 72px;
    width: 72px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
  }
}

.modal-md {
  width: 350px !important;
}

.payment-button-group {
  button {
    border-radius: 0.5rem;
    border: 1px solid $secondary-tint-2;
    &:hover {
      border-color: $secondary-tint-1;
      > span {
        border-color: $secondary-tint-1;
      }
    }
    &:focus,
    &:active {
      background: $primary-light !important;
      border-color: $primary-light !important;
      > span {
        background-color: $primary !important;
        outline: 1px solid $primary !important;
        border: 2px solid $primary-light !important;
      }
    }
    > span {
      background-color: $white;
      border: 1px solid $secondary-tint-2;
    }
  }
}

.hover-primary:hover {
  background-color: $primary !important;
  color: white !important;
}

.time-selected {
  background-color: $primary !important;
  color: white !important;
}

.ReactModal__Content {
  padding: 0 !important;
}
