.pay-method-img {
  width: 120px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  img {
    vertical-align: middle;
    width: auto;
  }
}
