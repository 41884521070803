@import '../scss/variables/color';

.service-card {
  height: 100% !important;
  .card-body {
    div {
      &:first-child {
        border-radius: 8px 0 0 8px;
      }
      &:last-child {
        border-radius: 0 8px 8px 0;
      }
    }
  }
}

.row {
  .service-container {
    &:first-child {
      .service-card {
        .card-body {
          div {
            &:last-child {
              background-color: rgba($warning-2, 0.15);
            }
          }
        }
      }
    }
    &:nth-child(2) {
      .service-card {
        .card-body {
          div {
            &:last-child {
              background-color: rgba($info-2, 0.15);
            }
          }
        }
      }
    }
    &:nth-child(3) {
      .service-card {
        .card-body {
          div {
            &:last-child {
              background-color: rgba($purple-2, 0.15);
            }
          }
        }
      }
    }
    &:last-child {
      .service-card {
        .card-body {
          div {
            &:last-child {
              background-color: rgba($success-2, 0.15);
            }
          }
        }
      }
    }
  }
}
