@import '../scss/variables/color';

.contact-card {
  position: relative;
  .contact-icon {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid $warning;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.contact-form {
  input,
  textarea {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid transparent;
    border-radius: 0;
    padding: 1rem;
    color: $secondary;
    &::placeholder {
      color: $gray;
    }
    &:focus {
      box-shadow: none;
      border-bottom: 1px solid $primary;
    }
  }
}
