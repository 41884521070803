.position-absolute {
  bottom: -11.5%;
}

.input-group {
  input,
  span {
    font-size: 11px;
  }
  input {
    border-radius: 8px 0 0 8px;
  }
  span {
    border-radius: 0 8px 8px 0;
  }
}
