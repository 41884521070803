@import './variables/font';

.deal-detail {
  p {
    font-size: 13px;
  }
  h6 {
    font-family: 'poppins-medium' !important;
    font-size: 17px;
  }
  small {
    font-size: 9px;
  }
}
