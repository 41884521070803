$sidebar-bg: #fafafa;
$sidebar-width: 21rem;
$sidebar-padding-x: 2.9rem;
$sidebar-padding-y: 1rem;

$sidebar-navlink-padding-y: 1rem;
$sidebar-navlink-padding-x: 1.5rem;
$sidebar-navlink-color: #737791;
$sidebar-navlink-hover-bg: #FFBB47;
$sidebar-navlink-hover-color: #434343;
$sidebar-navlink-active-color: #fff;
$sidebar-navlink-icon-size: 1.75rem;
$sidebar-navlink-text-size: 1.125rem;
$sidebar-navlink-radius: 1rem;