/* toaster */
.toast-message {
  background: rgb(0, 0, 0) !important;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px) !important;
  border-radius: 12px !important;
  font-family: sans-serif !important;

  color: #ffffff !important;
  font-weight: 500 !important;
  width: fit-content !important;

  font-size: 0.8rem !important;
  padding: 10px 10px !important;
}
.toastProgress {
  background: #ffffff !important;
  height: 0.2rem !important;
}

.toast-icon {
  font-size: 1.1rem !important;
  padding: auto 10px !important;
  color: #ffffff !important;
}
.Toastify__close-button {
  color: rgb(20, 11, 31) !important;
}

.cursor-pointer {
  cursor: pointer;
}
