@import '../variables/sidebar-vars';
@import '../supplier-dashboard/Table-styles.scss';

.dashboard-container {
    margin-left: $sidebar-width;
    @media (max-width: 991px) {
        margin-left: 0;
    }
    .dashboard-navbar {
        background-color: transparent;
        .navbar-container {
            display: flex;
            flex-basis: 100%;
            flex-grow: 1;
            align-items: center;
        }
        .navbar-nav {
            width: 100%;
            input {
                font-size: 1.125rem;
            }
            .nav-link {
                padding-top: 1rem;
                font-size: 1.125rem;
                &.route-link {
                    font-weight: 700;
                    font-size: 1.75rem;
                }
            }
        }
    }
}
.shape-square {
    display: flex;
    align-items: center;
    width: 100%;
    aspect-ratio: 1 / 1;
}

.badge-bubble {
    width: 14px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
}

.tab-pane {
    padding: 0 1.25rem;
}
