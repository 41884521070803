@import '../scss/variables/color';
@import '../scss/variables/mixins';

.pet-profile {
  .pet-img {
    position: relative;
    width: 100%;
    .pet-img-tag {
      border: 1px dashed $primary;
    }
    button {
      position: absolute;
      bottom: -15px;
      right: -15px;
    }
  }
  input {
    &::placeholder {
      width: 100%;
      transition: all ease-in 0.2s;
    }
    &:focus {
      &::placeholder {
        width: 0;
      }
    }
  }
  .checkbox-container {
    margin-bottom: 0.825rem;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 20px;
    input {
      opacity: 0;
      width: 0;
      height: 0;
      &:checked {
        + .slider {
          background-color: tint($primary, 80%);
          border-color: tint($primary, 80%);
          &:before {
            transform: translateX(13px);
          }
        }
      }
      &:focus + .slider {
        box-shadow: 0 0 1px tint($primary, 80%);
      }
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
      border: 1px solid rgba($secondary, 0.15);
      -webkit-transition: 0.4s;
      transition: 0.4s;
      &.round {
        border-radius: 34px;
        &:before {
          border-radius: 50%;
          box-shadow: 0 0 4px 0 rgba($secondary, 0.25);
        }
      }
      &:before {
        position: absolute;
        content: '';
        height: 16px;
        width: 16px;
        left: 1px;
        bottom: 1px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
    }
  }
}
