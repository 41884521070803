@import '../scss/theme/custom-bootstrap';

.navbar {
  // position: fixed !important;
  width: 100%;
  // left: 0;
  // top: 0;
  background-color: $primary-light;
  .nav-link {
    color: $secondary;
    font-family: 'poppins-medium';
  }
}

.search-input {
  position: relative;
  input {
    padding-left: 2.25rem !important;
  }
  span {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}
