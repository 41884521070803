@import '../variables/color';
@import '../variables/font';
@import 'bootstrap/scss/functions';

// default bs variable override
$font-family-sans-serif: 'poppins-regular';
$navbar-brand-padding-y: 0;
$navbar-padding-y: 0;
$navbar-nav-link-padding-x: 0.625rem;
$nav-link-font-size: 0.875rem;
$card-spacer-y: 0.875rem;
$progress-height: 0.45rem;

@import 'bootstrap/scss/variables';

// custom api for bootstrap
$utilities: (
  'viewport-height': (
    property: height,
    class: vh,
    values: (
      100: 100vh,
      75: 75vh,
    ),
  ),
  'font-weight': (
    property: font-weight,
    class: fw,
    values: (
      light: $font-weight-light,
      normal: $font-weight-normal,
      semibold: $font-weight-semibold,
      bold: $font-weight-bold,
    ),
  ),
  'width': (
    property: width,
    class: w,
    responsive: true,
    values: (
      auto: auto,
      100: 100%,
      98: 98%,
      95: 95%,
      90: 90%,
      84: 84%,
      80: 80%,
      75: 75%,
      65: 65%,
      60: 60%,
      55: 55%,
      50: 50%,
      45: 45%,
      40: 40%,
      35: 35%,
      33: 33%,
      30: 30%,
      27: 27%,
      25: 25%,
      23: 23%,
      22: 22%,
      20: 20%,
      19: 19%,
      12: 12.5%,
    ),
  ),
  'height': (
    property: height,
    class: h,
    responsive: true,
    values: (
      auto: auto,
      100: 100%,
      98: 98%,
      95: 95%,
      90: 90%,
      84: 84%,
      80: 80%,
      75: 75%,
      65: 65%,
      60: 60%,
      55: 55%,
      50: 50%,
      45: 45%,
      40: 40%,
      35: 35%,
      33: 33%,
      30: 30%,
      27: 27%,
      25: 25%,
      23: 23%,
      22: 22%,
      20: 20%,
      19: 19%,
      12: 12.5%,
    ),
  ),
  'font-size': (
    property: font-size,
    responsive: true,
    class: fs,
    values: (
      1: $h1-font-size,
      2: $h2-font-size,
      3: $h3-font-size,
      4: $h4-font-size,
      5: $h5-font-size,
      6: $h6-font-size,
      xl: 5rem,
      md: 0.875rem,
      sm: 0.75rem,
    ),
  ),
  'line-height': (
    property: line-height,
    responsive: true,
    class: lh,
    values: (
      1: 1,
      1-25: 1.25,
      1-50: 1.5,
      1-75: 1.75,
      2: 2,
    ),
  ),
  'opacity': (
    property: opacity,
    responsive: true,
    class: o,
    values: (
      100: 100%,
      75: 75%,
      50: 50%,
      25: 25%,
      0: 0,
    ),
  ),
);

$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem,
  7: 2rem,
  8: 1.5rem,
  9: 1rem,
  10: 0.87rem,
);

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'warning': $warning,
  'success': $success,
  'dark': $dark,
  'danger': $danger,
  'info': $info,
);

// Create your own map
$custom-colors: (
  'light-100': $light-100,
  'light-200': $light-200,
  'primary-light': $primary-light,
  'primary-dark': $primary-dark,
  'gray': $gray,
  'warning-light': $warning-light,
  'warning-2': $warning-2,
  'info-2': $info-2,
  'red-2': $red-2,
  'purple-2': $purple-2,
  'success-2': $success-2,
  'gray-100': $gray-100,
  'light-2': $light-2,
  'snow-white': $snow-white,
  'primary-shade-1': $primary-shade-1,
  'white': $white,
  'secondary-tint-1': $secondary-tint-1,
  'primary-tint-1': $primary-tint-1,
  'secondary-tint-2': $secondary-tint-2,
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/utilities/api';

.hover-text-warning {
  &:hover {
    color: $warning-2;
  }
}

.form-control,
.form-select {
  border: 1px solid #e2e8f0 !important;
  padding: 0.9rem !important;
  border-radius: 12px !important;
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  -ms-border-radius: 12px !important;
  -o-border-radius: 12px !important;
}

.btn {
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  border-radius: 12px !important;
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  -ms-border-radius: 12px !important;
  -o-border-radius: 12px !important;
}
