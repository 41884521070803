@import '../variables/color';
@import '../variables/mixins';

.table {
    thead {
        tr {
            th {
                color: tint($gray, 50%);
                font-weight: normal;
                font-size: 0.845rem;
                text-align: center;
                &:nth-child(1),&:nth-child(2) {
                    text-align: left;
                }
            }
        }
    }
    tbody {
        tr {
            td {
                font-size: 0.875rem;
                padding-top: 1rem;
                padding-bottom: 1rem;
                vertical-align: middle;
                &:last-child {
                    text-align: right;
                }
            }
            &:nth-child(4n + 1) {
                td{
                    > .progress {
                        background-color: tint($blue, 80%);
                        .progress-bar {
                            background-color: $blue;
                        }
                    }
                    > .value {
                        background-color: tint($blue, 90%);
                        color: $blue;
                        border:1px solid $blue;
                    }
                }
            }
            &:nth-child(4n + 2) {
                td{
                    > .progress {
                        background-color: tint($mint-green, 80%);
                        .progress-bar {
                            background-color: $mint-green;
                        }
                    }
                    > .value {
                        background-color: tint($mint-green, 90%);
                        color: $mint-green;
                        border:1px solid $mint-green;
                    }
                }
            }
            &:nth-child(4n + 3) {
                td{
                    > .progress {
                        background-color: tint($violet, 80%);
                        .progress-bar {
                            background-color: $violet;
                        }
                    }
                    > .value {
                        background-color: tint($violet, 90%);
                        color: $violet;
                        border:1px solid $violet;
                    }
                }
            }
            &:nth-child(4n) {
                td{
                    > .progress {
                        background-color: tint($orange, 80%);
                        .progress-bar {
                            background-color: $orange;
                        }
                    }
                    > .value {
                        background-color: tint($orange, 90%);
                        color: $orange;
                        border:1px solid $orange;
                    }
                }
            }
            &:last-child {
                td {
                    border-bottom: none;
                }
            }
        }
    }
}